import { format } from 'date-fns';
import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { IDataDropdown } from '@/interfaces/dropdown';
import { IVesselItemDropdown } from '@/interfaces/vessel';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IRequestCreateCompanyVessel,
  TResponseGetVesselList,
  IRequestCompanyVesselCrewList,
  IRequestCompanyVesselCrewStatistic,
  IReturnCompanyVesselCrewList,
  IReturnCompanyVesselCrewStatistic,
  IResponseGetVessels,
  IRequestDeleteCompanyVessel,
  IRequestParamGetCompanyVessel,
  IResponseGetCompanyVessel,
  IReturnGetCompanyVessel,
  IRequestEditCompanyVessel,
  IRequestGetVesselDetail,
  IReturnGetVesselDetail,
  IRequestAddVesselCrewRank,
  IRequestGetCompanyVesselCrewListByRankId,
  IResponseGetCompanyVesselCrewListByRankId,
  IRequestDeleteVesselRank,
  IReturnGetVesselAuditLog,
  IResponseGetVesselAuditLog,
  IRequestGetVesselAuditLog,
  IRequestCreateVesselLog,
  IReturnGetVesselCompanyCrewNote,
  IRequestGetVesselCompanyCrewNote,
  IRequestPostVesselCompanyCrewNote,
  IResponseCompanyVesselCrewList,
} from './type';
import { PAGINATION_LIMIT } from '@/constants/pagination';

export const vesselApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'vesselApi',
  tagTypes: [
    'GET_COMPANY_VESSEL',
    'VESSEL_CREW_LIST',
    'COMPANY_VESSEL_LIST',
    'VESSEL_DETAIL_INFO',
    'VESSEL_DETAIL_STATISTIC',
    'VESSEL_AUDIT_LOG',
  ],
  endpoints: builder => ({
    searchVessel: builder.query<IVesselItemDropdown[], string>({
      query: (keyword: string) => `/v1/company/vessel?vesselName=${keyword}`,
      transformResponse: (response: IResponseGetVessels) => {
        return response.vessels.map(el => ({
          value: el.id,
          label: el.name,
          countryCode: el.countryIso,
          grossTonage: el?.grossTonnage ?? 0,
          typeSpecific: el?.typeSpecific ?? '',
          imo: el?.imo ?? '',
          deadWeight: el?.deadWeight ?? 0,
        }));
      },
    }),
    searchVesselFull: builder.query<IResponseGetVessels, string>({
      query: (keyword: string) => `/v1/company/vessel?vesselName=${keyword}`,
    }),

    getCompanyVesselList: builder.query<IDataDropdown[], void>({
      query: () => `/v1/company/company-vessel/list`,
      transformResponse: (response: TResponseGetVesselList) => {
        return response.map(el => ({
          value: el.id,
          label: el.label,
        }));
      },
      providesTags: ['COMPANY_VESSEL_LIST'],
    }),

    getCompanyVesselCrewList: builder.query<
      IReturnCompanyVesselCrewList,
      IRequestCompanyVesselCrewList
    >({
      query: payload => ({
        url: `/v1/company/company-vessel/${payload.companyVesselId}/crew`,
        params: {
          startContract: payload.startContract.toISOString(),
          endContract: payload.endContract.toISOString(),
        },
        method: 'GET',
      }),
      providesTags: ['VESSEL_CREW_LIST'],
      transformResponse: (response: IResponseCompanyVesselCrewList) => ({
        meta: response.meta,
        items: response.items.map(el => ({
          ...el,
          crews: el.crews
            ? el.crews?.map(elCrew => ({
                ...elCrew,
                companyCrewContractId: elCrew.companyCrewId,
              }))
            : null,
        })),
      }),
    }),

    // company vessel
    getCompanyVessel: builder.query<IReturnGetCompanyVessel, IRequestParamGetCompanyVessel>({
      providesTags: ['GET_COMPANY_VESSEL'],
      query: ({ page, keyword }) => ({
        url: '/v1/company/company-vessel',
        method: 'GET',
        params: {
          page,
          keyword,
        },
      }),
      transformResponse: (response: IResponseGetCompanyVessel) => {
        return {
          pagination: response.meta,
          list: response.items || [],
        };
      },
    }),

    getVesselDetail: builder.query<IReturnGetVesselDetail, IRequestGetVesselDetail>({
      query: payload => ({
        url: `/v1/company/company-vessel/${payload.companyVesselId}`,
        method: 'GET',
      }),
      providesTags: ['VESSEL_DETAIL_INFO'],
    }),

    deleteCompanyVessel: builder.mutation<void, IRequestDeleteCompanyVessel>({
      query: payload => ({
        url: `/v1/company/company-vessel/${payload.vesselId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GET_COMPANY_VESSEL'],
    }),

    postCreateCompanyVessel: builder.mutation<void, IRequestCreateCompanyVessel>({
      query: payload => ({
        url: `/v1/company/company-vessel`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['GET_COMPANY_VESSEL', 'COMPANY_VESSEL_LIST'],
    }),

    addVesselCrewRank: builder.mutation<void, IRequestAddVesselCrewRank>({
      query: payload => ({
        url: `/v1/company/company-vessel/${payload.companyVesselId}/rank`,
        method: 'POST',
        body: {
          rankId: payload.rankId,
        },
      }),
      invalidatesTags: [
        'VESSEL_DETAIL_INFO',
        'VESSEL_CREW_LIST',
        'VESSEL_DETAIL_STATISTIC',
        'VESSEL_AUDIT_LOG',
      ],
    }),

    editCompanyVessel: builder.mutation<void, IRequestEditCompanyVessel>({
      query: (payload: IRequestEditCompanyVessel) => ({
        url: `/v1/company/company-vessel`,
        method: 'PATCH',
        body: payload.data,
      }),
      invalidatesTags: [
        'VESSEL_DETAIL_INFO',
        'VESSEL_CREW_LIST',
        'VESSEL_DETAIL_STATISTIC',
        'GET_COMPANY_VESSEL',
        'VESSEL_AUDIT_LOG',
      ],
    }),

    getCompanyVesselCrewStatistic: builder.query<
      IReturnCompanyVesselCrewStatistic,
      IRequestCompanyVesselCrewStatistic
    >({
      query: payload => ({
        url: `/v1/company/company-vessel/${payload.companyVesselId}/statistic`,
        method: 'GET',
      }),
      providesTags: ['VESSEL_DETAIL_STATISTIC'],
    }),

    getCompanyVesselCrewListByRankId: builder.query<
      IDataDropdown[],
      IRequestGetCompanyVesselCrewListByRankId
    >({
      query: payload => ({
        url: `/v1/company/company-vessel/${payload.companyVesselId}/rank/${payload.companyVesselRankId}/crew`,
        method: 'GET',
      }),
      transformResponse: (response: IResponseGetCompanyVesselCrewListByRankId) => {
        /**
         * Formats a label with the crew name and date range.
         *
         * @param props - An object containing the following properties:
         *   @param name - The name of the crew.
         *   @param startDate - The start date of the crew's activity in ISO string format.
         *   @param endDate - The end date of the crew's activity in ISO string format.
         * @returns A formatted string in the format: "name dd/MM/yyyy - dd/MM/yyyy".
         *
         * @example
         * const crewData = {
         *   name: 'Alpha Team',
         *   startDate: '2023-06-01',
         *   endDate: '2023-06-15'
         * };
         *
         * const label = formattedLabel(crewData);
         * console.log(label); // Output: "Alpha Team 01/06/2023 - 15/06/2023"
         * ```
         */
        const formattedLabel = ({
          ...props
        }: {
          name: string;
          startDate: string;
          endDate: string;
        }) => {
          return `${props.name} (${format(new Date(props.startDate), 'dd/MM/yyyy')} - ${format(
            new Date(props.endDate),
            'dd/MM/yyyy'
          )})`;
        };
        return (
          response?.items?.map(el => ({
            value: el.companyCrewId,
            label: formattedLabel({
              name: el.crewName,
              startDate: el.startDate,
              endDate: el.endDate,
            }),
          })) ?? []
        );
      },
    }),

    deleteVesselRank: builder.mutation<void, IRequestDeleteVesselRank>({
      query: payload => ({
        url: `/v1/company/company-vessel/${payload.vesselId}/rank/${payload.companyVesselRankId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'COMPANY_VESSEL_LIST',
        'GET_COMPANY_VESSEL',
        'VESSEL_CREW_LIST',
        'VESSEL_DETAIL_INFO',
        'VESSEL_DETAIL_STATISTIC',
        'VESSEL_AUDIT_LOG',
      ],
    }),

    getVesselAuditLog: builder.query<IReturnGetVesselAuditLog, IRequestGetVesselAuditLog>({
      query: payload => ({
        url: `/v1/company/company-vessel/${payload.vesselId}/audit-log`,
        method: 'GET',
        params: {
          page: payload.page,
          limit: PAGINATION_LIMIT,
        },
      }),
      providesTags: ['VESSEL_AUDIT_LOG'],
      transformResponse: (response: IResponseGetVesselAuditLog) => {
        return {
          pagination: response.meta,
          list: response.items || [],
        };
      },
    }),

    createVesselLog: builder.mutation<void, IRequestCreateVesselLog>({
      query: payload => ({
        url: `/v1/company/company-vessel/${payload.vesselId}/audit-log/note`,
        method: 'POST',
        body: {
          note: payload.notes,
        },
      }),
      invalidatesTags: ['VESSEL_AUDIT_LOG'],
    }),
    getVesselCompanyCrewNote: builder.query<
      IReturnGetVesselCompanyCrewNote,
      IRequestGetVesselCompanyCrewNote
    >({
      query: ({ companyCrewId, companyVesselId }) => ({
        url: `/v1/company/company-vessel/${companyVesselId}/crew/${companyCrewId}/note`,
        method: 'GET',
      }),
    }),

    postVesselCompanyCrewNote: builder.mutation<void, IRequestPostVesselCompanyCrewNote>({
      query: ({ companyCrewId, companyVesselId, note }) => ({
        url: `/v1/company/company-vessel/${companyVesselId}/crew/${companyCrewId}/note`,
        method: 'POST',
        body: {
          note,
        },
      }),
    }),
  }),
});

export const {
  useCreateVesselLogMutation,
  useGetVesselAuditLogQuery,
  useDeleteVesselRankMutation,
  useLazyGetCompanyVesselCrewListQuery,
  useGetVesselDetailQuery,
  useGetCompanyVesselCrewStatisticQuery,
  useGetCompanyVesselCrewListQuery,
  useLazyGetCompanyVesselCrewListByRankIdQuery,
  useSearchVesselQuery,
  useLazySearchVesselQuery,
  useLazySearchVesselFullQuery,
  useGetCompanyVesselListQuery,
  useGetCompanyVesselQuery,
  useDeleteCompanyVesselMutation,
  usePostCreateCompanyVesselMutation,
  useEditCompanyVesselMutation,
  useAddVesselCrewRankMutation,
  useLazyGetVesselCompanyCrewNoteQuery,
  usePostVesselCompanyCrewNoteMutation,
} = vesselApi;
