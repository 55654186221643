import { createApi } from '@reduxjs/toolkit/query/react';
import { mantisAuthenticatedApiConfig } from '@/config/api/mantis';
import { IDownloadAllResponse } from '@/interfaces/downloadAll/type';
import {
  IRequestGetDownloadAllMyCrewDocument,
  IRequestGetRequestDownloadAllMyCrewDocument,
  IResponseGetRequestDownloadAllMyCrewDocument,
} from './type';

export const downloadDocumentsApi = createApi({
  ...mantisAuthenticatedApiConfig,
  reducerPath: 'downloadDocumentApi',
  endpoints: builder => ({
    getRequestDownloadAllDocuments: builder.query<{ message: string }, { crewId: string }>({
      query: ({ crewId }) => ({
        url: `/v1/company/request-archive/documents/${crewId}`,
        method: 'GET',
      }),
    }),
    getDownloadAllDocuments: builder.query<IDownloadAllResponse, { crewId: string }>({
      query: ({ crewId }) => ({
        url: `/v1/company/archive/documents/${crewId}`,
        method: 'GET',
      }),
    }),

    /**
     * My Crew: Request Download All Document
     */
    getRequestDownloadAllMyCrewDocuments: builder.query<
      IResponseGetRequestDownloadAllMyCrewDocument,
      IRequestGetRequestDownloadAllMyCrewDocument
    >({
      query: ({ crewCompanyAssignmentId }) => ({
        url: `/v1/company/company-assignment/${crewCompanyAssignmentId}/request-archive/document`,
        method: 'GET',
      }),
    }),

    /**
     * My Crew: Download All Document
     */
    getDownloadAllMyCrewDocuments: builder.query<
      IDownloadAllResponse,
      IRequestGetDownloadAllMyCrewDocument
    >({
      query: ({ crewCompanyAssignmentId }) => ({
        url: `/v1/company/company-assignment/${crewCompanyAssignmentId}/archive/document`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetRequestDownloadAllDocumentsQuery,
  useLazyGetDownloadAllDocumentsQuery,
  useLazyGetDownloadAllMyCrewDocumentsQuery,
  useLazyGetRequestDownloadAllMyCrewDocumentsQuery,
} = downloadDocumentsApi;
