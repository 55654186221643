import * as Sentry from '@sentry/react';
import packageJson from '@/../package.json';

const environment =
  import.meta.env.VITE_MANTIS_URL === 'https://mantis.redikru.com' ? 'production' : 'development';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://6c318ea2e23241db8557c5eb464fc659@o4505448972550144.ingest.sentry.io/4505589209628672',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
      }),
      new Sentry.Replay(),
    ],
    release: packageJson.version,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment,
  });
}
