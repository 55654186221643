import { RootState } from '@/store';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import i18n from 'i18next';
import queryString from 'query-string';

export const mantisPublicApiConfig = {
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_MANTIS_URL }),
  headers: {
    'accept-language': i18n.language,
  },
};

export const mantisAuthenticatedApiConfig = {
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_MANTIS_URL,
    paramsSerializer: (params: Record<string, unknown>) =>
      queryString.stringify(params, { arrayFormat: 'none' }),
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      // set language
      headers.set('accept-language', i18n.language);

      return headers;
    },
  }),
};
